<template>
  <div class="replayOrpublish">
    <div class="compile">
      <Input v-model="value" maxlength="100"
             show-word-limit type="textarea"
             placeholder="你想说些什么呢..."
             style="width: 80%;"/>
      <Button
        class="btn"
        type="primary"
        @click="publish">发表
      </Button>
    </div>
    <List class="leaveContent">
      <!-- 留言 -->
      <Modal
        title="回复内容"
        v-model="isShow"
        @on-ok="handelInputContent"
        :mask-closable="false">
        <Input v-model="replyValue" type="textarea" placeholder="输入回复内容..."/>
      </Modal>
      <div v-for="(item, index) in messageData" :key="index">
        <ListItem ref="contentItem" class="contentItem" :style="{color: Color}">
          <div class="head">
            <!--            <p v-show="publishURL === '/web-api/message/reply' || publishURL === '/web-api/comment/reply'"-->
            <!--               class="number_id">{{index+1}} 楼</p>-->
            <!--            <p v-show="publishURL === '/web-api/message'|| publishURL === '/web-api/comment'" class="number_id">-->
            <!--              {{item.id}} 楼</p>-->
            <p class="number_id">{{index + 1}}楼</p>
            <img :src="item.avatar">
            <a>{{item.nickName}}<span v-show="item.userName === 'coffeeo'" class="chief">站长</span></a>
          </div>
          <span>{{item.content}}</span>
          <p>{{item.createTime| dateFilter}}</p>
          <p @click="SetReplyInfo(item)" class="reply">回复</p>
        </ListItem>
        <!-- 回复 -->
        <div class="replyContent" v-for="(replyItem, j) in item.replies" :key="j">
          <ListItem :style="{background: bgcolor}" ref="replyItem" class="replyItem">
            <p :style="{color: Color}" class="number_id">{{j+1}} 楼</p>
            <p class="reply_access">
            <p :style="{color: Color}" class="content">
              <img :src="replyItem.avatar"> 回复<a> @{{replyItem.replyNickName}}</a>: {{replyItem.content}}</p>
            <p class="reply_name">{{replyItem.nickName}}</p>
            <p :style="{color: Color}" class="reply_time">
              {{replyItem.createTime | dateFilter}}
              <span v-show="replyItem.userName === 'coffeeo'" class="chief">站长</span>
            </p>
            <p @click="SetPaddingReply(replyItem, item)" class="reply">回复</p>
          </ListItem>
        </div>
      </div>
    </List>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'replyOrpublish',
    props: {
      messageData: {
        type: Array,
        default() {
          return []
        }
      },
      publishURL: {
        type: String,
        default: ''
      },
      replyURL: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        value: '',
        isShow: false,
        replyInfo: {},
        replyValue: '',
        arrMesasgeList: [],
        leaveReplyVO: {},
        obj: {}
      };
    },
    filters: {
      dateFilter(val) {
        return moment(val).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    computed: {
      Color() {
        return this.$store.state.Color
      },
      bgcolor() {
        return this.$store.state.Color == '#333' ? "#f2f2f2" : 'transparent'
      }
    },
    methods: {
      /*一级回复 item为当前遍历的留言或评论*/
      SetReplyInfo(item) {
        //item.date = moment(item.date).format('YYYY-MM-DD HH:mm:ss')
        console.log('item', item)
        //处理留言的一级回复
        if (this.replyURL === '/web-api/message/reply') {
          this.replyInfo.replyUserName = item.userName;
          this.replyInfo.leaveId = item.id;
        }
        //处理评论的一级回复
        if (this.replyURL === '/web-api/comment/reply') {
          this.replyInfo.replyUserName = item.userName;
          this.replyInfo.commentId = item.id
        }
        // this.replyInfo.article_type = this.$route.params.id
        this.isShow = true
      },
      /*二级回复，*/
      SetPaddingReply(selfItem, parentItem) {
        // item.datetime = moment(selfitem.datetime).format('YYYY-MM-DD HH:mm:ss')
        // item.name = selfitem.name // 嵌套回复的名字
        // item.username = parentItem.username // 嵌套回复的祖先名字
        // item.date = moment(parentItem.date).format('YYYY-MM-DD HH:mm:ss') // 祖先时间
        // this.replyInfo.Info = item
        // this.replyInfo.Info.article_type = this.$route.params.id
        // this.replyInfo.index = index
        //将本级的昵称赋给父级，
        //处理留言的一级回复
        if (this.replyURL === '/web-api/message/reply') {
          this.replyInfo.replyUserName = selfItem.userName;
          this.replyInfo.leaveId = parentItem.id;
        }
        //处理评论的一级回复
        if (this.replyURL === '/web-api/comment/reply') {
          this.replyInfo.replyUserName = selfItem.userName;
          this.replyInfo.commentId = parentItem.id
        }
        this.isShow = true
      },
      /*回复*/
      handelInputContent() {
        const token = localStorage.getItem('token')
        if (!token) return this.$Message.error('您还没有登陆呢！')
        if (!this.replyValue) return this.$Message.error('内容不能为空呀,请输入内容！')
        this.replyInfo.content = this.replyValue
        //this.leaveReplyVO.message = this.replyInfo.Info
        //console.log('message->', this.leaveReplyVO.message)
        //this.leaveReplyVO.content = this.replyInfo.replyValue
        //   PostMessage(this.replyURL, {replyInfo: this.replyInfo})
        //     .then(res => {
        //       debugger
        //       if (res.data.err === 0) {
        //         this.$Message.success(res.data.message)
        //         setTimeout(() => {
        //           location.reload()
        //         }, 1500);
        //       } else {
        //         this.$Message.success(res.data.message)
        //       }
        //     })
        //     .catch(reason => {
        //       this.$Message.error(reason.message)
        //     })
        // },
        this.$axios.post(this.replyURL, this.replyInfo).then(res => {
          if (res.data.code === 200) {
            this.$Message.success(res.data.message)
            setTimeout(() => {
              location.reload()
            }, 1000);
          } else {
            this.$Message.error(res.data.message)
          }
        }).catch(reason => {
          this.$Message.error(reason.message)
        })
      },
      publish() {
        if (localStorage.getItem("token")) {
          /* 如果 value不为空的话  */
          if (this.value) {
            //const token = localStorage.getItem('token');
            /* detail start */
            let that = this;
            if (this.publishURL === '/web-api/comment') {
              //文章的评论发表请求
              this.obj = {
                articleId: this.$route.params.id,
                content: that.value
              }
            }
            /* detail end */
            /* message message start */
            if (this.publishURL === '/web-api/message') {
              //留言的发表请求
              this.obj = {content: this.value}
            }
            /* message message end */
            // PostMessage(this.publishURL, this.obj)
            //   .then(res => {
            //     if (res.data.err == 0) {
            //       this.$Message.success(res.data.message)
            //       setTimeout(() => {
            //         location.reload()
            //       }, 1000);
            //     } else {
            //       this.$Message.error(res.data.message)
            //     }
            //   })
            this.$axios.post(this.publishURL, this.obj).then(res => {
              if (res.data.code === 200) {
                this.$Message.success(res.data.message)
                setTimeout(() => {
                  location.reload()
                }, 1000);
              } else if (res.data.code === 401) {
                localStorage.removeItem("token");
                this.$router.push('/login')
              } else {
                this.$Message.error(res.data.message);
              }
              console.log(res);
            })
          }
          /* 如果为空我们就不发起请求 给用户提示不能为空 */
          else {
            this.$Message.error("小主信息不能为空哦,(ノへ￣、)！")
          }
        } else {
          this.$Message.error("请先去登陆再来留言哦,(ノへ￣、)！")
        }
      },
    },
  }

</script>
<style lang='scss' scoped>
  .replayOrpublish {
    .btn {
      margin: 1rem;
      z-index: 5;
      position: relative;
    }

    .compile {
      margin: 2rem;
      position: relative;
      z-index: 5;
    }

    .leaveContent {
      position: relative;
      z-index: 5;

      img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
      }

      .contentItem {
        padding: 0.8rem 1rem 0.2rem 1rem;
        position: relative;
        border-bottom: 1px solid #bbb;

        p, img, a {
          position: absolute;
          font-size: 0.8rem;
        }

        a {
          color: red;
          left: 4rem;
          top: .6rem;

          .chief {
            color: orange;
            white-space: nowrap;
            font-weight: bold;
            margin-left: .5rem;
          }
        }

        span {
          margin-top: 1rem;
          padding: 0 3rem;
          text-align: left;
          font-size: 0.8rem;
          padding-top: 0.5rem;
        }

        img {
          top: 0.5rem;
          left: 1.5rem;
        }

        p {
          top: 0.5rem;
          right: 1rem;;
        }

        p.number_id {
          right: 10rem;
        }
      }

      p.reply {
        cursor: pointer;
        top: 2.3rem;
        color: rgb(0, 132, 255);
      }

      p.reply:hover {
        color: blue;
      }

      .replyContent {
        padding-left: 50px;
        font-size: 0.8rem;

        .replyItem {
          border-bottom: 1px solid #eee;
          background: #fffefe;
          border-left: 2.5px solid #eee;
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          align-items: flex-start;

          img {
            left: -2rem;
          }

          .reply {
            position: absolute;
            width: 2rem;
            white-space: nowrap;
            right: 0.8rem;
          }

          .reply_access {
            margin-top: 1rem;
            white-space: nowrap;
          }

          p.content {
            padding-left: 0.5rem;
            padding-right: 3rem;
            text-align: left;
            color: #333;
          }

          p.reply_name {
            position: absolute;
            left: 2.8rem;
            font-size: 0.8rem;
            color: red;
          }

          p.reply_time {
            position: absolute;
            left: 9rem;
            font-size: 0.8rem;
            color: #333;

            .chief {
              color: orange;
              white-space: nowrap;
              font-weight: bold;
              margin-left: .5rem;
            }
          }

          p.number_id {
            position: absolute;
            right: 1rem;
            top: 1rem;
            color: #333;
          }
        }
      }
    }
  }
</style>